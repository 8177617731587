import { IApiError } from "models/apiError.model";
import { IPagination } from "models/pagination.model";

export const EMAIL_TEMPLATE_SLICE_NAME = "emailTemplate";

export interface EmailTemplateResponse {
  _id: string;
  templateName: string;
  content: string;
  installers: string[];
}

export interface EmailTemplate {
  templateName: string;
  content: string;
}

export interface EmailTemplateState {
  isLoading: boolean;
  error: IApiError | null;
  templates: EmailTemplateResponse[];
  pagination: IPagination | null;
}

export const initialState: EmailTemplateState = {
  isLoading: false,
  error: null,
  templates: [],
  pagination: null,
};
