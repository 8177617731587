import { Fragment, createElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import { useHistory } from "react-router";
import { PopupModal } from "react-calendly";
import call_icon from "assets/svgs/header/call_icon.svg";
import sidebar_opener from "assets/svgs/header/sidebar_opener.svg";
import applogo_new from "assets/svgs/svgs/applogo_new.svg";
import SwitchView from "./SwitchView";
import { useAppDispatch, useAppState } from "redux/store";
import LanguageDropdown from "pages/Layout/Components/LanguageDropdown/LanguageDropdown";
import { selectUserDetails } from "redux/user/selectors";
import { logOut } from "redux/user/userSlice";
import { Button } from "shared/ui";
import CallNow from "shared/components/CallNow/CallNow";
const { Header } = Layout;
const pageSettings = {
  backgroundColor: "ffffff",
  hideEventTypeDetails: false,
  hideLandingPageDetails: false,
  primaryColor: "00a2ff",
  textColor: "4d5055",
};
const utm = {
  utmCampaign: "Spring Sale 2019",
  utmContent: "Shoe and Shirts",
  utmMedium: "Ad",
  utmSource: "Facebook",
  utmTerm: "Spring",
};

const Headerbar = ({
  state,
  toggle,
  toggle_mobile,
  switchview,
  setswitchview,
}: any) => {
  const history = useHistory();
  const [isOpen, setisOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const currentUser = useAppState(selectUserDetails);
  const [prefill, setprefill] = useState({
    email: currentUser?.email,
    firstName:
      currentUser?.firstName?.split(" ").slice(0, -1).join(" ") || "yash",
    lastName:
      currentUser?.firstName?.split(" ").slice(-1).join(" ") || "shivhare",
    name: currentUser?.firstName || "yash shivhare",
    date: new Date(Date.now() + 86400000),
  });

  const logoutUser = () => {
    dispatch(logOut());
    history.push("/login");
  };

  return (
    <Fragment>
      <Header className="site p-0 relative">
        <div className="flex items-center w-full flex-wrap">
          <div className="flex items-center ml-3 md:hidden gap-2">
            <div className="translate-icon-5">
              {createElement(state ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: "trigger",
                onClick: toggle,
              })}
            </div>
            <div className="flex justify-center items-center text-xl font-semibold">
              <div>
                {history.location.pathname == "/overview" && (
                  <>
                    <span>{t("Solar Project")} </span>
                    <span className="lg:hidden"> - {t("statusView")}</span>
                  </>
                )}
                {history.location.pathname == "/listing" && (
                  <>
                    <span>{t("Solar Project")} </span>
                    <span className="lg:hidden"> - {t("projectListing")}</span>
                  </>
                )}
                {history.location.pathname == "/mapview" && (
                  <>
                    <span>{t("Solar Project")} </span>
                    <span className="lg:hidden"> - {t("mapListing")}</span>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="hidden justify-between ml-2 mr-2 items-center w-full md:flex">
            <div
              className="flex items-center gap-2 hover:cursor-pointer"
              onClick={() => history.push("/listing")}
            >
              <div className="h-10 w-10 relative">
                <img
                  src={applogo_new}
                  className="h-full w-full align-middle cursor-pointer"
                  alt=""
                />
              </div>
              <div className="text-white text-2xl">SolarHub</div>
            </div>
            <div className="flex gap-1">
              <div className="w-12 h-12 p-3" onClick={() => setisOpen(true)}>
                <img
                  src={call_icon}
                  alt=""
                  className="w-full h-full align-middle cursor-pointer"
                />
              </div>
              <div onClick={toggle_mobile} className="w-12 h-12 p-2">
                <img
                  src={sidebar_opener}
                  alt=""
                  className="w-full h-full align-middle cursor-pointer"
                />
              </div>
            </div>
          </div>
          <div className="md:hidden">
            <SwitchView switchview={switchview} setswitchview={setswitchview} />
          </div>
        </div>

        <div className="flex justify-between items-center mr-5 gap-4 md:hidden">
          <LanguageDropdown />
          <CallNow />
          <Button onClick={logoutUser}>{t("Logout")}</Button>
        </div>
      </Header>
      <PopupModal
        url="https://calendly.com/experte-solarhub"
        pageSettings={pageSettings}
        utm={utm}
        prefill={prefill}
        onModalClose={() => setisOpen(false)}
        open={isOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        //@ts-ignore
        rootElement={document.getElementById("root")}
      />
    </Fragment>
  );
};

export default Headerbar;
