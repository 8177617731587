import styled from "styled-components/macro";

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  gap: 2px;
  align-items: center;
  width: max-content;
  .call-now {
    font-weight: 600;
    /* font-size: 18px; */
    color: var(--blue);
    padding-left: 10px;
  }
  .hideOnMobile {
    @media (max-width: 1100px) {
      display: none;
    }
  }
`;
