import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Menu as AntdMenu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  LangaugeItems,
  getLangaugeMenuConfig,
} from "pages/Layout/helpers/getLangaugeMenuConfig";
import { PREFERRED_LANGUAGE } from "shared/constants/common";
import { Button, Dropdown, Menu } from "shared/ui";
import appCookiesStorage from "shared/utils/appCookies";

const LanguageDropdown = () => {
  const { t, i18n } = useTranslation();
  const handleClick = useCallback(
    ({ key }: { key: string }) => {
      const keyCode = key === LangaugeItems.ENGLISH ? "en" : "de";
      if (i18n.language === keyCode) return;
      void i18n.changeLanguage(keyCode);
      appCookiesStorage.setItem(PREFERRED_LANGUAGE, key);
    },
    [i18n]
  );
  const menuConfig = getLangaugeMenuConfig();

  const langaugeMenu = useMemo(() => {
    const items = menuConfig.map((item) => (
      <AntdMenu.Item key={item.key}>{item.title}</AntdMenu.Item>
    ));
    return <Menu onClick={handleClick}>{items}</Menu>;
  }, [handleClick, menuConfig]);

  return (
    <Dropdown
      overlay={langaugeMenu}
      trigger={["click"]}
      placement="bottomCenter"
    >
      <Button>
        <span>{t("selectedLang")}</span>
        <DownOutlined className="align-middle mr-1" />
      </Button>
    </Dropdown>
  );
};

export default LanguageDropdown;
