import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Tooltip } from "antd";
import listing_view from "assets/svgs/listing/listing_view.svg";
import kanban from "assets/svgs/listing/kanban.svg";
import Line from "assets/svgs/listing/Line.svg";
import google_maps from "assets/svgs/listing/google_maps.svg";
import kanban_yellow from "assets/svgs/listing/kanban_yellow.svg";
import data from "assets/svgs/left_sidebar/data.svg";
import data1 from "assets/svgs/left_sidebar/data1.svg";
import Listing1 from "assets/svgs/listing/Listing1.svg";
import maps1 from "assets/svgs/listing/maps1.svg";
import { useAppState } from "redux/store";
import {
  selectInstallerDetails,
  selectUserDetails,
} from "redux/user/selectors";
import {
  ProductIcon,
  ProductActiveIcon,
  constantIcon,
  constantActiveIcon,
} from "assets";

const SwitchView = ({ switchview, setswitchview, responsive }: any) => {
  const userDetails = useAppState(selectUserDetails);
  const installerDetails = useAppState(selectInstallerDetails);
  let location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    switch (location.pathname) {
      case "/listing":
        setswitchview(1);
        break;
      case "/mapview":
        setswitchview(2);
        break;
      case "/overview":
        setswitchview(3);
        break;
      case "/data":
        setswitchview(4);
        break;
      case "/product":
        setswitchview(5);
        break;
      case "/profile":
        setswitchview(6);
        break;
      default:
        setswitchview(5);
    }
  }, [location]);

  return (
    <div
      className={
        !responsive
          ? "flex items-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
          : "flex justify-center items-center mx-auto"
      }
    >
      <div className="mr-2 text-base md:text-sm">{t("Switch View")}</div>
      <Tooltip
        placement="top"
        title={<span>{t("Listing")}</span>}
        trigger={"hover"}
      >
        <div className="cursor-pointer flex justify-center items-center listing-top-icon w-10 h-9 p-1 listing-icon xl:w-8 xl:h-7 lg:w-7 lg:h-6">
          <img
            className="w-full h-full"
            onClick={() => {
              history.push("/listing");
            }}
            src={switchview === 1 ? Listing1 : listing_view}
            alt=""
          />
        </div>
      </Tooltip>

      <div className="flex justify-center items-center w-4 h-8">
        <img className="w-full h-full" src={Line} alt="" />
      </div>
      <Tooltip
        trigger={"hover"}
        placement="top"
        title={<span>{t("mapListing")}</span>}
      >
        <div className="cursor-pointer flex justify-center items-center listing-top-icon w-9 h-8 listing-icon xl:w-8 xl:h-7 lg:w-7 lg:h-6">
          <img
            className="w-full h-full"
            onClick={() => {
              history.push("/mapview");
            }}
            src={switchview === 2 ? google_maps : maps1}
            alt=""
          />
        </div>
      </Tooltip>

      {userDetails?.role !== "CITY_ADMIN" && (
        <div className="flex justify-center items-center w-4 h-8">
          <img className="w-9 h-8" src={Line} alt="" />
        </div>
      )}
      {userDetails?.role !== "CITY_ADMIN" && (
        <Tooltip
          trigger={"hover"}
          placement="top"
          title={<span>{t("Kanban")}</span>}
        >
          <div className="cursor-pointer flex justify-center items-center listing-top-icon w-9 h-9 listing-icon xl:w-8 xl:h-7 lg:w-7 lg:h-6">
            <img
              className="w-full h-full p-1"
              onClick={() => {
                history.push("/overview");
              }}
              src={switchview === 3 ? kanban_yellow : kanban}
              alt=""
            />
          </div>
        </Tooltip>
      )}
      {(userDetails?.role === "ADMIN" ||
        userDetails?.role === "CITY_ADMIN") && (
        <div className="flex justify-center items-center w-4 h-8">
          <img className="w-9 h-8" src={Line} alt="" />
        </div>
      )}
      {(userDetails?.role === "ADMIN" ||
        userDetails?.role === "CITY_ADMIN") && (
        <Tooltip
          trigger={"hover"}
          placement="top"
          title={<span>{t("solarhubView")}</span>}
        >
          <div className="cursor-pointer flex justify-center items-center listing-top-icon w-9 h-9 listing-icon xl:w-8 xl:h-7 lg:w-7 lg:h-6">
            <img
              className="w-full h-full p-1"
              onClick={() => {
                history.push("/data");
              }}
              src={switchview === 4 ? data : data1}
              alt=""
            />
          </div>
        </Tooltip>
      )}
      {installerDetails?._id && userDetails?.role !== "CITY_ADMIN" && (
        <div className="flex justify-center items-center w-4 h-8">
          <img className="w-9 h-8" src={Line} alt="" />
        </div>
      )}
      {installerDetails?._id && userDetails?.role !== "CITY_ADMIN" && (
        <Tooltip
          trigger={"hover"}
          placement="top"
          title={<span>{t("Product")}</span>}
        >
          <div className="cursor-pointer flex justify-center items-center listing-top-icon w-9 h-9 listing-icon xl:w-8 xl:h-7 lg:w-7 lg:h-6">
            <img
              className="w-full h-full p-1"
              onClick={() => {
                history.push("/product");
              }}
              src={switchview === 5 ? ProductActiveIcon : ProductIcon}
              alt=""
            />
          </div>
        </Tooltip>
      )}
      {installerDetails?._id && userDetails?.role !== "CITY_ADMIN" && (
        <div className="flex justify-center items-center w-4 h-8">
          <img className="w-9 h-8" src={Line} alt="" />
        </div>
      )}
      {installerDetails?._id && userDetails?.role !== "CITY_ADMIN" && (
        <Tooltip
          trigger={"hover"}
          placement="top"
          title={<span>{t("Settings")}</span>}
        >
          <div className="cursor-pointer flex justify-center items-center listing-top-icon w-9 h-9 listing-icon xl:w-8 xl:h-7 lg:w-7 lg:h-6">
            <img
              className="w-full h-full p-1"
              onClick={() => {
                history.push("/profile");
              }}
              src={switchview === 6 ? constantActiveIcon : constantIcon}
              alt=""
            />
          </div>
        </Tooltip>
      )}

      {/* <div className="flex justify-center items-center w-4 h-8">
        <img className="w-9 h-8" src={Line} alt="" />
      </div> */}
    </div>
  );
};

export default SwitchView;
