import {
  IAddInstallerDTO,
  IDuplicareProductDTO,
  IGetProductsResponse,
  IUpdateInstallerDTO,
  getProductEndpoints,
} from "models/product.model";
import { ApiResponse, httpClient } from "shared/utils";

class ProductAPI {
  static getProducts({
    installerId,
  }: {
    installerId?: string;
  }): ApiResponse<IGetProductsResponse> {
    const { GET_PRODUCTS } = getProductEndpoints({ installerId });
    return httpClient.get<IGetProductsResponse>(GET_PRODUCTS);
  }

  static addInstallerProduct(
    data: IAddInstallerDTO
  ): ApiResponse<IGetProductsResponse> {
    const { PRODUCT_INSTALLER } = getProductEndpoints({});
    return httpClient.post<IGetProductsResponse>(PRODUCT_INSTALLER, data);
  }

  static updateInstallerProduct(
    data: IUpdateInstallerDTO
  ): ApiResponse<IGetProductsResponse> {
    const { UPDATE_INSTALLER } = getProductEndpoints({});
    return httpClient.put<IGetProductsResponse>(UPDATE_INSTALLER, data);
  }

  static removeInstallerProduct(
    data: IAddInstallerDTO
  ): ApiResponse<IGetProductsResponse> {
    const { REMOVE_INSTALLER } = getProductEndpoints({});
    return httpClient.post<IGetProductsResponse>(REMOVE_INSTALLER, data);
  }

  static duplicateInstallerProduct(
    data: IDuplicareProductDTO
  ): ApiResponse<IGetProductsResponse> {
    const { DUPLICATE_PRODUCT } = getProductEndpoints(data);
    return httpClient.get<IGetProductsResponse>(DUPLICATE_PRODUCT);
  }

  static updateProductDetails(data: any): ApiResponse<IGetProductsResponse> {
    const { productId, ...remainingData } = data;
    const { UPDATE_PRODUCT } = getProductEndpoints({ productId });
    return httpClient.put<IGetProductsResponse>(UPDATE_PRODUCT, {
      ...remainingData,
    });
  }

  static deleteProduct(data: any): ApiResponse<IGetProductsResponse> {
    const { productId } = data;
    const { DELETE_PRODUCT } = getProductEndpoints({});
    return httpClient.put<IGetProductsResponse>(DELETE_PRODUCT, {
      deletedProducts: [productId],
    });
  }
}

export default ProductAPI;
