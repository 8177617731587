import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import {
  SAVED_PROJECT_SLICE_NAME,
  savedProjectState,
  initialState,
} from "./models";
import { getAllSavedProject, loadMoreSavedProject } from "./actionCreators";

export const savedProjectSlice = createSlice({
  name: SAVED_PROJECT_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isFulfilled(getAllSavedProject),
        (state: savedProjectState, action) => {
          const { payload } = action;
          state.savedProject = payload.data.docs;
          state.pagination = payload.data.pagination;
          state.maxMin = payload.data.maxMin[0];
        }
      )
      .addMatcher(
        isPending(getAllSavedProject, loadMoreSavedProject),
        (state: savedProjectState) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isRejected(getAllSavedProject, loadMoreSavedProject),
        (state: savedProjectState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
          console.log(error, "error");
        }
      )
      .addMatcher(
        isFulfilled(loadMoreSavedProject),
        (state: savedProjectState, action) => {
          const { payload } = action;
          state.savedProject = [...state.savedProject, ...payload.data.docs];
          state.pagination = payload.data.pagination;
          state.maxMin = payload.data.maxMin[0];
        }
      );
  },
});

export default savedProjectSlice.reducer;
