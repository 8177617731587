import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./CallNow.styles";
import { useAppDispatch } from "redux/store";
import { CallNowIcon } from "assets";
import { openHubspotModal } from "redux/subscription/subscriptionSlice";


const CallNow: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const openHubspotPopup = useCallback(() => {
    dispatch(openHubspotModal());
  }, [dispatch]);

  return (
    <S.Container onClick={openHubspotPopup}>
      <img src={CallNowIcon}/>
      <span className="call-now hideOnMobile">{t("CALL NOW")}</span>
    </S.Container>
  );
};

export default CallNow;
