import React, { Fragment, lazy, Suspense, useEffect, useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useAppDispatch, useAppState } from "redux/store";
import {
  selectInstallerDetails,
  selectIsAutorized,
} from "redux/user/selectors";

import DashBoardLayout from "components/templates/DashBoard";
import { getProducts } from "redux/product/actionCreators";
import { getAllPanels } from "redux/user/actionCreators";
import { CircularProgress } from "@mui/material";

// Lazy-loaded components
const Login = lazy(() => import("pages/auth/login"));
const Register = lazy(() => import("pages/auth/register"));
const Listing = lazy(() => import("pages/listing/Listing"));
const ListingwithMap = lazy(() => import("pages/listingwithMap/Listing"));
const Overview = lazy(() => import("pages/overviewNew/Overview"));
const Iframe = lazy(() => import("pages/iframe/iframe"));
const Product = lazy(() => import("pages/product/Product"));
const Profile = lazy(() => import("pages/profile/profile"));
// const NewObj = lazy(() => import("pages/listing/components/ObjViewerModal/newObj")); // Uncomment if needed

// Define your dynamic routes
const dynamicRoutes = [
  {
    path: "/listing",
    component: Listing,
  },
  {
    path: "/overview",
    component: Overview,
  },
  {
    path: "/mapview",
    component: ListingwithMap,
  },
  {
    path: "/data",
    component: Iframe,
  },
  {
    path: "/product",
    component: Product,
  },
  {
    path: "/profile",
    component: Profile,
  },
];

const Routes: React.FC = () => {
  const isAuthorized = useAppState(selectIsAutorized);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const adminToken = queryParams.get("admin");
  const dispatch = useAppDispatch();
  const installerDetails = useAppState(selectInstallerDetails);

  useEffect(() => {
    dispatch(getProducts({ installerId: installerDetails?._id }));
  }, [installerDetails]);

  useEffect(() => {
    dispatch(getAllPanels());
  }, [dispatch]);

  const Loader = useMemo(() => {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <div className="w-fit h-fit">
          <CircularProgress />
        </div>
      </div>
    );
  }, []);

  return (
    <Suspense fallback={Loader}>
      <Switch>
        {(!isAuthorized || adminToken) && (
          <Fragment>
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            {/* <Route
              exact
              path='/test'
              component={() => NewObj({ quote: {}, installerDetails: {},  mtlUrl:'https://prod-photogrammetry-api-outputsbucket107f8b6f-dpavlhd8ojqg.s3.eu-central-1.amazonaws.com/bakedModels/65bcd90765da202b57a78f6f/odm_texturing/small_model/odm_textured_model_geo.obj.mtl', objUrl:  'https://prod-photogrammetry-api-outputsbucket107f8b6f-dpavlhd8ojqg.s3.eu-central-1.amazonaws.com/bakedModels/65bcd90765da202b57a78f6f/odm_texturing/small_model/odm_textured_model_geo.obj' })}
            /> */}
            <Redirect to="/login" />
          </Fragment>
        )}

        {isAuthorized ? (
          <DashBoardLayout>
            {dynamicRoutes.map((route) => (
              <Route
                key={route.path}
                exact
                path={`${route.path}`}
                component={route.component}
              />
            ))}
            <Redirect to={`${dynamicRoutes[0].path}`} />
          </DashBoardLayout>
        ) : (
          <Redirect to="/login" />
        )}
      </Switch>
    </Suspense>
  );
};

export default Routes;
