import { English, German } from "assets";
import { ReactNode } from "react";

export enum LangaugeItems {
  ENGLISH = "English",
  GERMAN = "German",
}

type TConfigItem = {
  titleKey: string;
  icon: string;
  key: LangaugeItems;
};

const menuItems: Record<LangaugeItems, TConfigItem> = {
  [LangaugeItems.ENGLISH]: {
    titleKey: "English",
    icon: English,
    key: LangaugeItems.ENGLISH,
  },
  [LangaugeItems.GERMAN]: {
    titleKey: "Deutsch",
    icon: German,
    key: LangaugeItems.GERMAN,
  },
};

const langaugeMenuConfig = [
  menuItems[LangaugeItems.ENGLISH],
  menuItems[LangaugeItems.GERMAN],
];

export const getLangaugeMenuConfig = () => {
  return langaugeMenuConfig.map((item) => ({
    ...item,
    title: item.titleKey,
  }));
};
